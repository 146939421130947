import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
const Header = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(() => {
    return localStorage.getItem("activeItem") || "home";
  });

  useEffect(() => {
    const path = location.pathname.substr(1);
    updateActiveItem(path || "home");
  }, [location]);

  const updateActiveItem = (newActiveItem) => {
    localStorage.setItem("activeItem", newActiveItem);
    setActiveItem(newActiveItem);
  };
  const [activeLink, setActiveLink] = useState("home");
  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);
  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const [searchToggled, setIsToggled] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsToggled(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleClick = () => {
    setIsToggled(prevState => !prevState); // Toggle the state based on the previous state
  };
  return (
    <>
      {/* start header section  */}
      <section className="second-header-sec">
        <Container>
          <Navbar expand="lg">
            <Navbar.Brand as={Link} to="/">
              <img className="headlogo" src={
                process.env.PUBLIC_URL + "/assets/Image/Logo/Local-MandaiFinal-Logo.png"} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Link as={Link} to="/" active={activeItem === "home"} onClick={handleNavItemClick}  >
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/near-me" active={activeItem === "near-me"} onClick={handleNavItemClick} >
                  Near Me
                </Nav.Link>
                <Nav.Link as={Link} to="/about-us" active={activeItem === "about-us"} onClick={handleNavItemClick}  >
                  About Us
                </Nav.Link>
                <Nav.Link as={Link} to="/contact-us" active={activeItem === "contact-us"} onClick={handleNavItemClick}>
                  Contact Us
                </Nav.Link>
                <Nav.Link as={Link} to="/blog" active={activeItem === "blog"} onClick={handleNavItemClick} >
                  Blog
                </Nav.Link>
                <Nav.Link as={Link} to="/become-seller" active={activeItem === "become-seller"} onClick={handleNavItemClick}>
                  Become a Seller
                </Nav.Link>
                <NavDropdown title="All Categories" id="basic-nav-dropdown" className="me-3">
                  <NavDropdown.Item onClick={handleNavItemClick}>Coffee Products</NavDropdown.Item>
                  <NavDropdown.Item onClick={handleNavItemClick}> Food Products </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleNavItemClick}>
                    Home Supplements, Utilities & Stationery
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleNavItemClick}> Beverages</NavDropdown.Item>
                  <NavDropdown.Item onClick={handleNavItemClick}>Vegetables</NavDropdown.Item>
                  <NavDropdown.Item onClick={handleNavItemClick}> Baby Needs</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/faq" active={activeItem === "faq"} onClick={handleNavItemClick} >
                  Faq
                </Nav.Link>
              </Nav>
              <div className="all-icon ms-auto mt-lg-0 mt-md-3 mt-sm-3 mt-3">
                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className="cart-logo" onClick={toggleClick} />
                <Link to={"/shop-now"}>
                  <FontAwesomeIcon icon="fa-solid fa-cart-shopping" className="cart-logo" onClick={handleNavItemClick} />
                </Link>
                <Link to={"/empty-wislist"}>
                  <FontAwesomeIcon icon={faHeart} className="cart-logo" onClick={handleNavItemClick} />
                </Link>
                <Link to={"/my-account"}>
                  <FontAwesomeIcon icon="fa-solid fa-user" className="cart-logo" onClick={handleNavItemClick} />
                </Link>
                <Link to={"/login"} className=" login">Login</Link>
                {searchToggled && (
                  <div ref={searchRef} className="input-group-box mb-3">
                    <Form.Control type="text" placeholder="Search here" />
                  </div>
                )}
              </div>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </section>
      {/* end header section  */}
    </>
  );
};

export default Header;
