import React from "react";
import "./History.css";
import { Breadcrumb, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Purchased_Items_Modal from "../../../Common_modal/Purchased_Items_Modal/Purchased_Items_Modal";
function History() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowOne, setModalShowTwo] = React.useState(false);
  return (
    <>
      <section className="my-account checkout-inner-steps ">
        <div className="container">
          <div className="account-table confirm_order Product_Cart">
            <div className="heading_holder">
              <Breadcrumb>
                <Breadcrumb.Item >
                  <Link to={"/"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item >
                  <Link to={"/my-account"}>My account</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active><Link to={"/order-history"}>Order history</Link></Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="back-text-holder">
              <Link to={"/my-account"}><p>Back</p></Link>
            </div>

            <div className="product_table">
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Order No.</th>
                    <th>Order Date</th>
                    <th>Delivery Date</th>
                    <th>Total</th>
                    <th>Order Status</th>
                    <th>Payment Type</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                    <th>Order Progress</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>#567865</td>
                    <td>20-02-24</td>
                    <td>21-02-24</td>

                    <td>₹500</td>
                    <td>-</td>
                    <td>Online</td>
                    <td className="green-text">Paid</td>
                    <td className="green-text">Delivered</td>
                    <td className="view-text" onClick={() => setModalShow(true)}>View</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>#567865</td>
                    <td>19-09-21</td>
                    <td>21-09-21</td>

                    <td>₹500</td>
                    <td>-</td>
                    <td>Online</td>
                    <td className="red-text">Unpaid</td>
                    <td className="yellow-text">Ordered</td>
                    <td className="view-text" onClick={() => setModalShow(true)}>View</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>#567865</td>
                    <td>19-09-21</td>
                    <td>21-09-21</td>

                    <td>₹500</td>
                    <td>-</td>
                    <td>Online</td>
                    <td className="red-text">Unpaid</td>
                    <td className="cancel-text">Canceled</td>
                    <td className="view-text" onClick={() => setModalShow(true)}>View</td>
                  </tr>
                </tbody>
              </Table>

              <div className="span-holder">
                <span>Showing 3 of 3 entries</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Purchased_Items_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default History;
