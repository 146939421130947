import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogIn from "../LogIn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";

function Register_Form() {
  const [showLogin, setShowLogin] = useState(false);

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  return (
    <>
      {showLogin ? (
        <LogIn />
      ) : (
        <div className="LogIn">
          <div className="row bg-color me-0 ms-0">
            <div className="col-md-7 p-0">
              <div className="row justify-content-center me-0 ms-0  ">
                <div className="col-md-6">
                  <div className="back_to_span">
                    <Link to={"/"}>
                      <p>Back to Home</p>
                    </Link>
                  </div>

                  <div className="heading-holder">
                    <h4>Register</h4>
                  </div>

                  <div className="log_in_form">
                    <Form>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control type="text" placeholder=" First Name" />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="">
                        <Form.Control type="email" placeholder=" Email" />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="phone_number"
                          placeholder="Mobile"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="">
                        <div className="password-field-holder">
                          <Form.Control
                            type="password"
                            placeholder=" Password"
                          />
                          <div className="eye-icon-holder ">
                            <FontAwesomeIcon
                              className="eye-icon"
                              icon="fa-solid fa-eye"
                            />
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="">
                        <div className="password-field-holder">
                          <Form.Control
                            type="password"
                            placeholder="Re-enter Password"
                          />
                          <div className="eye-icon-holder ">
                            <FontAwesomeIcon
                              className="eye-icon"
                              icon="fa-solid fa-eye"
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </Form>

                    <div className="btn-holder">
                      <Link to={"/"}>
                        <Common_Button className="SubmitBtn" text="  Create" />
                      </Link>
                    </div>

                    <div className="create_account mb-5">
                      <div className="login_text">
                        <p>
                          Already have an account? {/* <Link to={"/login"}> */}
                          <span
                            className="login_text"
                            onClick={handleLoginClick}
                          >
                            LOGIN
                          </span>
                          {/* </Link> */}
                        </p>
                      </div>
                    </div>

                    <div className="log_in_with">
                      <p>or register with social accounts</p>
                    </div>

                    <div className="social_img_holder">
                      <img
                        className="social_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/log_in/google.png"
                        }
                        alt="social_img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 p-0">
              <div className="img-section">
                <div className="img-holder">
                  <img
                    className="log_in_img"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/log_in/log_in.png"
                    }
                    alt="log_in_img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Register_Form;
