import React from 'react'
import ProductBanner from '../product-banner/ProductBanner'
import AllProductDetails from './All-product-details/AllProductDetails'

const ViewAllProduct = () => {
  return (
    <>
      <ProductBanner />
      <AllProductDetails/>
    </>
  )
}

export default ViewAllProduct