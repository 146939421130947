import React from 'react'
import './CounterSec.css'
import CountUp from 'react-countup'
const CounterSec = () => {
    return (
        <>
            <section className='counter-sec'>
                <div className='container'>
                    <div className="bg-image">
                        <div className='imag-overlay'></div>
                        <div className='row justify-content-center'>
                            <div className='heading-holder text-center mb-5'>
                                <h2>Track real-time sales and customer engagement with our dynamic e-commerce website counter section</h2>
                            </div>

                            <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-4'>
                                <div className='border-line'>
                                    <div className='total-holder text-center'>
                                        <h4><CountUp start={0} end={500} duration={4} decimals={0} /> M+</h4>
                                        <p>Products</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-4'>
                                <div className='border-line'>
                                    <div className='total-holder text-center'>
                                        <h4><CountUp start={0} end={200} duration={4} decimals={0} /> K+</h4>
                                        <p>Suppliers</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-4'>
                                <div className='total-holder text-center'>
                                    <h4><CountUp start={0} end={500} duration={4} decimals={0} /> +</h4>
                                    <p>Product categories</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CounterSec