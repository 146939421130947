import React from 'react'
import './ProductBanner.css'
const ProductBanner = () => {
    return (
        <>
            <section className='product-banner'>
                <div className='container'>
                    <div className='row'>
                        <div className='d-flex'>
                            <div className='circle-holder text-center'>
                                <img className='ecartlogo' src={process.env.PUBLIC_URL + '/assets/Image/product/e-cart.png'} alt="ecartlogo" />
                            </div>
                            <div className='name-holder'>
                                <h1>E Cart Grocery Seller</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductBanner