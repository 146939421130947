import React, { useState } from "react";
// import './vegetable-tabs/v';
import { Link } from "react-router-dom";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import Veggies_Section_Card from "../../../Common-Component/Veggies_Section_Card/Veggies_Section_Card";
import Category_Card from "../../../Common-Component/Category_Card/Category_Card";
import Veiw_All_Card from "../../../Common-Component/Veiw_All_Card/Veiw_All_Card";

const FruitsTab = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeHearts, setActiveHearts] = useState([]);
  const handleHeartClick = (index) => {
    const updatedHearts = [...activeHearts];
    updatedHearts[index] = !updatedHearts[index];
    setActiveHearts(updatedHearts);
  };
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const fruitDetails = [
    {
      vegetablename: "Mango Totapuri Premium",
      weight: "2 Pcs (Approx. 630 - 740 gm)",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/product/mango.png",
    },
    {
      vegetablename: "Watermelon",
      weight: "1 Pc (Big)",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/product/watermelon.png",
    },
    {
      vegetablename: "Orange Nagpur Premium",
      weight: "4 Pcs (Approx. 770 - 910 gms)",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/product/orange-fruit.png",
    },
    {
      vegetablename: "Grapes Black Jumbo",
      weight: "(500 gms)",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/product/fresh-grape.png",
    },
    {
      vegetablename: "Mango Totapuri Premium",
      weight: "2 Pcs (Approx. 630 - 740 gm)",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/product/mango.png",
    },
    {
      vegetablename: "Watermelon",
      weight: "1 Pc (Big)",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/product/watermelon.png",
    },
    {
      vegetablename: "Orange Nagpur Premium",
      weight: "4 Pcs (Approx. 770 - 910 gms)",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/product/orange-fruit.png",
    },
    {
      vegetablename: "Grapes Black Jumbo",
      weight: "(500 gms)",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/product/fresh-grape.png",
    },
  ];
  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="fruitfirst">
        <Row className="vegetable-sec mt-5">
          <Col
            lg={3}
            md={4}
            sm={6}
            className="px-lg-0 px-md-0 px-sm-none px-none "
          >
            <div className="bg-color">
              <div className="product-name py-3">
                <h2 className="ms-3">Fruits</h2>
              </div>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="fruitfirst">Daily Fruits</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fruitsecond">Exotic Fruits</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fruitthird">Seasonal Fruits</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fruitfourth">Cut Fruits</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Col>
          <Col lg={9} md={8} className="px-lg-0 px-md-0 px-sm-none px-none">
            <Tab.Content>
              <Tab.Pane eventKey="fruitfirst">
                <Row>
                  <Col
                    lg={3}
                    md={6}
                    sm={12}
                    className="px-lg-0 px-md-0 px-sm-none px-none"
                  >
                    <Category_Card
                      image="/assets/Image/product/high-angle.png"
                      discount="SALE 30% OFF"
                      heading="Fresh Fruits"
                      discountClassName="percentage"
                      headingClassName="namefruit"
                    />
                  </Col>
                  <Col
                    lg={9}
                    md={6}
                    sm={12}
                    className="px-lg-0 px-md-0 px-sm-none px-none"
                  >
                    <Swiper
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        420: {
                          slidesPerView: 2,
                        },
                        486: {
                          slidesPerView: 2,
                        },
                        768: {
                          slidesPerView: 1,
                        },
                        992: {
                          slidesPerView: 3,
                        },
                        1200: {
                          slidesPerView: 3,
                        },
                        1400: {
                          slidesPerView: 4,
                          spaceBetween: 0,
                        },
                      }}
                      navigation={false}
                      // modules={[Navigation]}
                      className="mySwiper1"
                      onSwiper={(swiper) => setSwiperInstance(swiper)}
                    >
                      {fruitDetails.map((item, index) => (
                        <SwiperSlide key={`${index}-${item.detailsLink}`}>
                          {index < fruitDetails.length - 1 ? (
                            <Veggies_Section_Card
                              detailsLink={item.detailsLink}
                              image={item.image}
                              vegetablename={item.vegetablename}
                              weight={item.weight}
                              price={item.price}
                              offprice={item.offprice}
                            />
                          ) : (
                            <Link to="/view-all-product">
                              <Veiw_All_Card />
                            </Link>
                          )}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div class="silder-btn">
                      <div
                        className="back-btn"
                        onClick={() => handleMainSliderClick("prev")}
                      ></div>
                      <div
                        className="next-btn"
                        onClick={() => handleMainSliderClick("next")}
                      ></div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="fruitsecond">Exotic Fruits</Tab.Pane>
              <Tab.Pane eventKey="fruitthird">Seasonal Fruits</Tab.Pane>
              <Tab.Pane eventKey="fruitfourth">Cut Fruits</Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default FruitsTab;
