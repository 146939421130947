import React, { useState } from "react";
import "./Product_Card.css";
import { Card, CardFooter } from "react-bootstrap";
import SupermarketModal from "../../Common_modal/Supermarket-modal/SupermarketModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faHeart } from '@fortawesome/free-solid-svg-icons'; // Import faHeart icon
function Product_Card({ price, subText, text, image }) {

  const [activeHearts, setActiveHearts] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const handleHeartClick = () => {
    setActiveHearts(!activeHearts);
  };



  return (
    <>
      {/* <div className="main-product-img-main mt-4">
        <Card>
          <div className="main-product-main mx-auto">
            <Card.Img
              variant="top"
              src="/assets/Image/Homepage/Our-Product/7p.png"
              className="product-img"
              alt="..."
            />
          </div>

          <div className="add-cart-sec ">
            <div onClick={() => setModalShow(true)}>
              <p>
                <FontAwesomeIcon
                  icon={faCartShopping}
                  className="cart-logo me-2 "
                />
                Add To Cart
              </p>
            </div>

            <div>
              <p>
                <FontAwesomeIcon
                  onClick={() => handleHeartClick()}
                  icon={faHeart} // Corrected icon usage
                  className={
                    activeHearts ? "cart-logo me-2 active" : "cart-logo me-2 "
                  }
                />
                Added To Wishlist
              </p>
            </div>
          </div>

          <Card.Body>
            <Card.Text className=" text-holder  mt-3">
              <h4>Thums Up 1 Lit.</h4>
              <span> Thums Up 1 Lit.</span>

              <p className="card-second-line">₹ 00.00/-</p>
            </Card.Text>
          </Card.Body>
        </Card>
      </div> */}

      <div className="main-product-img-main mt-4 mb-5">
        <Card>
          <div className="main-product-main mx-auto">
            <Card.Img
              variant="top"
              src={image}
              className="product-img"
              alt="..."
            />
          </div>

          <div className="add-cart-sec ">
            <div onClick={() => setModalShow(true)}>
              <p>
                <FontAwesomeIcon
                  icon={faCartShopping}
                  className="cart-logo me-2 "
                />
                Add To Cart
              </p>
            </div>

            <div>
              <p>
                <FontAwesomeIcon
                  onClick={() => handleHeartClick()}
                  icon={faHeart} // Corrected icon usage
                  className={
                    activeHearts ? "cart-logo me-2 active" : "cart-logo me-2 "
                  }
                />
                Added To Wishlist
              </p>
            </div>
          </div>

          <Card.Body>
            <Card.Text className=" text-holder  mt-3">
              <h4>{text}</h4>
              <span>{subText}</span>

              <p className="card-second-line">{price}</p>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

      <SupermarketModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Product_Card;