import React from "react";
import "./AllProductDetails.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Veggies_Section_Card from "../../../Common-Component/Veggies_Section_Card/Veggies_Section_Card";
import { Prev } from "react-bootstrap/esm/PageItem";
import Product_Card from "../../../Common-Component/Product_Card/Product_Card";
import Popular_Product_Card from "../../../Common-Component/Popular_Product_Card/Popular_Product_Card";
const AllProductDetails = () => {
  const VegetableProducts = [
    {
      name: "Nescafe Classic Instant Coffee...",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/1p.png",
    },
    {
      name: "Kurkure Toofaani Mirch Chips",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/2p.png",
    },
    {
      name: "Beet Root 1 KG",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/3p.png",
    },
    {
      name: "Himalaya Moisturizing Body Lotion",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/4p.png",
    },
    {
      name: "Huggies Wonder Pants",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/5p.png",
    },
    {
      name: "Dettol Antiseptic Liquid",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/6p.png",
    },
    {
      name: "Thums Up 1 Lit.",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/7p.png",
    },
    {
      name: "Nescafe Classic Instant Coffee...",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/1p.png",
    },
  ];
  const allData = [
    {
      image: "/assets/Image/Homepage/Select-Categories/vegetable.png",
      alt: "Fruits & Vegetables",
      content: "Daily Vegetables",
      eventKey: "first",
    },
    {
      image: "/assets/Image/Homepage/Select-Categories/exotic-vegetable.png",
      alt: "Exotic Vegetables",
      content: "Exotic Vegetables",
      eventKey: "second",
    },
    {
      image: "/assets/Image/Homepage/Select-Categories/seasoning.png",
      alt: "Herbs & Seasoning",
      content: "Herbs & Seasoning",
      eventKey: "third",
    },
    {
      image: "/assets/Image/Homepage/Select-Categories/sprout.png",
      alt: "Sprouts",
      content: "Sprouts",
      eventKey: "fourth",
    },
  ];
  return (
    <>
      <section className="all-product-details">
        <Container>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/product">E Cart Grocery Seller</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Vegetables</Breadcrumb.Item>
            </Breadcrumb>
            <div className="heading-holder mt-5 mb-4">
              <h2 className="title ">Vegetables</h2>
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="mb-5 me-0 ms-0 select-categories">
                <Nav variant="pills" className="flex-row p-0">
                  <Swiper
                    className="mySwiper1"
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      420: {
                        slidesPerView: 2,
                      },
                      486: {
                        slidesPerView: 2,
                      },
                      768: {
                        slidesPerView: 3,
                      },
                      992: {
                        slidesPerView: 3,
                      },
                      1200: {
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {allData.map((slide, index) => (
                      <SwiperSlide key={index} className="product-slide">
                        <Nav.Item>
                          <Nav.Link eventKey={slide.eventKey}>
                            <Popular_Product_Card
                              className="product-image"
                              content={slide.content}
                              alt={slide.alt}
                              image={slide.image}
                              imgClassName="category-image"
                            />
                             <div className="triangle-right"></div>
                          </Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Nav>
              </Row>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    {VegetableProducts.map((product, index) => (
                      <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>
                        <Link to="/vendor-page">
                          <div className="mb-3">
                            {/* <Product_Card /> */}
                            <Product_Card
                              image={product.image}
                              text={product.name}
                              subText={product.subText}
                              price={product.price}
                            />
                          </div>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">Exotic Vegetables</Tab.Pane>
                <Tab.Pane eventKey="third">Herbs & Seasoning</Tab.Pane>
                <Tab.Pane eventKey="fourth">Sprouts</Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AllProductDetails;
