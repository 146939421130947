import React from "react";
import "./Cancle_Reason_Modal.css";
import { Form, Modal } from "react-bootstrap";

function Cancle_Reason_Modal(props) {
  return (
    <>
      <div className="Reason_Modal">
        <Modal
          className=" Cancel_Reason_Modal"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            {" "}
            Select a reason for cancellation
          </Modal.Header>
          <Modal.Body>
            <div className="select_reason">
              <Form>
                <Form.Check
                  label="Product price has reduced"
                  name="name"
                  type="radio"
                />
                <Form.Check
                  label="Wrong contact number entered"
                  name="name"
                  type="radio"
                />
                <Form.Check label="Ordered by mistake" name="name" type="radio" />
                <Form.Check label="Incorrect product size/colour/type ordered" name="name" type="radio" />
                <Form.Check label="Purchased product from somewhere else" name="name" type="radio" />
                <Form.Check label="Wrong address selected" name="name" type="radio" />
                <Form.Check label="Product not required anymore" name="name" type="radio" />
                <Form.Check label="Incorrect payment method selected" name="name" type="radio" />
                <Form.Check label="Expected delivery time is too long" name="name" type="radio" />
                <Form.Check label="Other" name="name" type="radio" />
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Cancle_Reason_Modal;
