import React, { useState } from "react";
import "./SelectByCategory.css";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import VegetableTab from "./vegetable-tabs/VegetableTab";
import FruitsTab from "./Fruit-tabs/FruitsTab";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Popular_Product_Card from "../../Common-Component/Popular_Product_Card/Popular_Product_Card";
const SeclectByCategory = () => {
  const categoryData = [
    {
      image: "/assets/Image/Homepage/Select-Categories/vegetable.png",
      alt: "Fruits & Vegetables",
      content: "Fruits & Vegetables",
      eventKey: "first",
    },
    {
      image: "/assets/Image/Homepage/Select-Categories/beverage.png",
      alt: "Beverages",
      content: "Beverages",
      eventKey: "second",
    },
    {
      image: "/assets/Image/Homepage/Select-Categories/electronic.png",
      alt: "Home Supplements, Utilities & Stationery",
      content: "Home Supplements, Utilities & Stationery",
      eventKey: "third",
    },
 
  ];
  return (
    <>
      <section className="select-by-category select-categories">
        <div className="container">
          <div className="row">
            <div className="heading-holder">
              <h2 className="title ">Select by Category</h2>
            </div>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="ms-0 me-0 mb-5">
              <Nav variant="pills" className="flex-row p-0">
                <Swiper
                  className="mySwiper1"
                  breakpoints={{
                    0: {
                      slidesPerView:1.5,
                      spaceBetween: 10,
                    },
                    420: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    486: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                  }}
                >
                  {categoryData.map((slide, index) => (
                    <SwiperSlide key={index} className="product-slide">
                      <Nav.Item>
                        <Nav.Link eventKey={slide.eventKey}>
                          <Popular_Product_Card
                            className="product-image"
                            content={slide.content}
                            alt={slide.alt}
                            image={slide.image}
                            imgClassName="category-image"
                          />
                          <div className="triangle-right"></div>
                        </Nav.Link>
                      </Nav.Item>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Nav>
            </Row>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <VegetableTab />
                <FruitsTab />
              </Tab.Pane>
              <Tab.Pane eventKey="second">Beverages</Tab.Pane>
              <Tab.Pane eventKey="third">
                Home Supplements, Utilities & Stationery
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default SeclectByCategory;
