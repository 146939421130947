import React from 'react'
import ProductDetail from '../VendorProduct/ProductDetail/ProductDetail'
import SecondVendor from '../VendorPage/SecondVendor/SecondVendor'
import RateAndReview from '../VendorPage/RateAndReview/RateAndReview'
const VendorProduct = () => {
  return (
    <>
      <ProductDetail />
      <RateAndReview />
      <SecondVendor heading="Frequently Bought" />
    </>
  )
}

export default VendorProduct
