import { React, useState } from "react";
import Container from "react-bootstrap/Container";
import "./PopularCategories.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import Card from "react-bootstrap/Card";
import Popular_Product_Card from "../../Common-Component/Popular_Product_Card/Popular_Product_Card";
const PopularCategories = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const PopularCategoriesDetails = [
    {
      content: "Coffee Products",
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Popular-Categories/nescafe.png",
    },
    {
      content: "Beverages",
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Popular-Categories/cold-drinks.png",
    },
    {
      content: "Home Appliances",
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Popular-Categories/set-contemporary.png",
    },
    {
      content: "Baby Needs",
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Popular-Categories/beauty-product.png",
    },
    {
      content: "Fast Food",
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Popular-Categories/fast-food.png",
    },
    {
      content: "Coffee Products",
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Popular-Categories/nescafe.png",
    },
    {
      content: "Beverages",
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Popular-Categories/cold-drinks.png",
    },
    {
      content: "Home Appliances",
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Popular-Categories/set-contemporary.png",
    },
    {
      content: "Baby Needs",
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Popular-Categories/beauty-product.png",
    },
    {
      content: "Fast Food",
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Popular-Categories/fast-food.png",
    },
  ];

  return (
    <>
      <section className="Popular-Categories">
        <Container>
          <div className="Categories">
            <h1 className="title">Popular Categories</h1>
          </div>
          <Swiper
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              486: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1400: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            className="mySwiper"
          >
            {PopularCategoriesDetails.map((item, index) => (
              <SwiperSlide key={index}>
                <Popular_Product_Card
                  className="main-section my-5"
                  content={item.content}
                  image={item.image}
                  imgClassName="products"
                  textClassName="product-text"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <div class="silder-btn">
            <div
              className="back-btn"
              onClick={() => handleMainSliderClick("prev")}
            ></div>
            <div
              className="next-btn"
              onClick={() => handleMainSliderClick("next")}
            ></div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default PopularCategories;
