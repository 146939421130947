import React, { useState } from "react";
import "./ForgotPassword.css";
import { Breadcrumb, Button, Container, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Password_Modal from "../../../Common_modal/Password_Modal/Password_Modal";
import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function ForgotPassword() {
  const [modalShow, setModalShow] = useState(false);

  const hideModalWithDelay = () => {
    setTimeout(() => {
      window.location.href = "my-account"; // Change window location to root route ("/")
      setModalShow(false);
    }, 3000); // Change the delay time as needed (3000 milliseconds = 3 seconds)
  };

  return (
    <>
      <section className="changepassword-section my-account">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/my-account"}>My account</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <Link to={"/change-password"}>Change Password</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="back-text-holder">
            <Link to={"/my-account"}>
              <p>Back</p>
            </Link>
          </div>

          <div>
            <h2 className="title">Change Password</h2>
          </div>


          <div className="row">
            <div className=" col-lg-6 col-md-10 col-sm-10 col-12 mx-auto">
              <div className="passwordmain">
                <div className="pass-heading">
                  <h2>Change Your Password</h2>
                </div>
                <div className="formpart">
                  <Form>
                    <Form.Control
                      className="mb-3"
                      type="password"
                      placeholder="current password"
                    />

                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="New Password"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <InputGroup.Text id="basic-addon2"><FontAwesomeIcon
                        className="eye-icon"
                        icon="fa-solid fa-eye"
                      /></InputGroup.Text>
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Re-Enter New Password"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <InputGroup.Text id="basic-addon2"><FontAwesomeIcon
                        className="eye-icon"
                        icon="fa-solid fa-eye"
                      /></InputGroup.Text>
                    </InputGroup>
                 
                  </Form>

                  <Common_Button
                    onClick={() => {
                      setModalShow(true);
                      hideModalWithDelay(); // Call hideModalWithDelay function when showing modal
                    }}
                    text="Submit"
                    className="submitbtn"
                  />
                  <Link to={"/my-account"}>
                    <p className="cancel">Cancel</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Successfull_Modal
        message=" Your password has been changed successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default ForgotPassword;
