import React, { useState } from "react";
import "./Purchase.css";
import { Breadcrumb, Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Order_Cancle from "../Order_Cancle/Order_Cancle";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
function Purchase(props) {
  const [showOrderCancel, setShowOrderCancel] = useState(false);

  const handleCancelClick = () => {
    setShowOrderCancel(true);
  };

  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 50;
  } else if (props.currentStep === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }
  const steps = [
    {
      links: "/order-history",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/history.png",
      goto: "Order History",
    },
    {
      links: "/information",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/account.png",
      goto: "Account Information",
    },
    {
      links: "/ChangePassword",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/password.png",
      goto: " Change Password",
    },
  ];
  return (
    <>
      <section className="my-account">
        <div className="container">
          <div className="heading_holder mb-4">
            {showOrderCancel ? (
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={"/"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => setShowOrderCancel(false)}>
                  <Link>My account</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <Link>Order Cancel</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            ) : (
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={"/"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <Link to={"/my-account"}>My account</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
          </div>

          {showOrderCancel ? (
            <Order_Cancle />
          ) : (
            <div>
              <div className="">
                <h2 className="title">My Account</h2>
              </div>

              <div className="product-section-div">
                <div className="row">
                  <div className="col-xxl-10 col-lg-9 col-md-8 col-sm-7">
                    <div>
                      <p className="date">Ordered Date : 20 Dec 2022</p>
                      <h6 className="items">Purchased Items 2</h6>
                      <h6 className="price">$600</h6>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-5">
                    <div>
                      <p className="date">Order ID : #867564756</p>
                      <p className="date">Payment Type : Online</p>
                      <p className="date">Payment Status : Paid</p>
                    </div>
                  </div>
                </div>

                <div className="product-div">
                  <div className="row">
                    <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-4">
                      <div className="product">
                        <img
                          className="img-set"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/My_Profile/cabbege.png"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-4">
                      <div className="product">
                        <img
                          className="img-set"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/My_Profile/tomato.png"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h6 className="order">Order Status</h6>
                <div className="stepper-div">
                  <ProgressBar percent={stepPercentage}>
                    <Step>
                      {({ accomplished, index }) => (
                        <div
                          className={`indexedStep ${accomplished ? "accomplished" : null
                            }`}
                        >
                          <div className="range-text-div">
                            <h6 className="placed">Order Placed</h6>
                            <p className="date">20 Dec</p>
                          </div>
                        </div>
                      )}
                    </Step>
                    <Step>
                      {({ accomplished, index }) => (
                        <div
                          className={`indexedStep ${accomplished ? "accomplished" : null
                            }`}
                        >
                          <div className="range-text-div">
                            <h6 className="placed">Processing</h6>
                            <p className="date">20 Dec</p>
                          </div>
                        </div>
                      )}
                    </Step>

                    <Step>
                      {({ accomplished, index }) => (
                        <div
                          className={`indexedStep ${accomplished ? "accomplished" : null
                            }`}
                        >
                          <div className="range-text-div">
                            <h6 className="placed">Shipped</h6>
                            <p className="date">Est. 30 Dec</p>
                          </div>
                        </div>
                      )}
                    </Step>

                    <Step>
                      {({ accomplished, index }) => (
                        <div
                          className={`indexedStep ${accomplished ? "accomplished" : null
                            }`}
                        >
                          <div className="range-text-div">
                            <h6 className="placed">Delivered</h6>
                            <p className="date">Est. 30 Dec</p>
                          </div>
                        </div>
                      )}
                    </Step>
                  </ProgressBar>
                </div>

                <div className="product-buttons">
                  <Common_Button
                    className=" buy"
                    text=" Buy again"

                  />

                  <Common_Button
                    onClick={handleCancelClick}
                    className=" cancel"
                    text=" Cancel"

                  />
                </div>
              </div>

              <div className="row mt-4 mb-4">
                {steps.map((stepes) => (
                  <div className="col-lg-4 col-md-6 mb-3">
                    <Link to={stepes.links}>
                      <div className="order-box">
                        <div className="row">
                          <div className="col-3">
                            <div className="order-fafa-circle">
                              <img
                                className="order-fafa-img"
                                src={stepes.icon}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-9">
                            <div className="order-text-div">
                              <p className="order-p">
                                {stepes.goto}{" "}
                                <span className="align-fafa">
                                  <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Purchase;
