import React from "react";
import "./BecomeASeller.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
const BecomeASeller = () => {
  return (
    <>
      <section className="become-a-seller">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="image-seller text-center">
                <img src={process.env.PUBLIC_URL + "/assets/Image/become-seller/become-seller.png"} className="become-img" />
              </div>
            </div>
            <div className="col-xxl-7  col-xl-7 col-lg-8 col-md-10 mx-auto">

              <div className=" form-seller">
                <h2 className="mb-4">Seller Registration Form</h2>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} md={6} controlId="formGridname" className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter name" />
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridMobile" className="mb-3">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control type="text" placeholder="Enter Mobile" />
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridEmail" className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter Email" />
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridURL" className="mb-3">
                      <Form.Label>Store URL</Form.Label>
                      <Form.Control type="text" placeholder="Enter Store URL" />
                    </Form.Group>
                  </Row>
                  <div className="text-center mt-4">
                    <Common_Button className="btn-sub" text=" Submit" />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BecomeASeller;
