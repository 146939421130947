import React from "react";
import "./TwoPayment.css";
import { Form } from "react-bootstrap";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";

const TwoPayment = ({ handlePrevious, handleNext }) => {
  return (
    <>
      <section className="checkout-inner-steps">
        <div className="container">
          <div className="adresmain">
            <h3>Thank You for Trusting Us</h3>
            <div className="adresss">
              <p className="primary">Primary Contact</p>
              <p className="disha">Disha</p>
              <p className="disha">
                Akshya Nagar 1st Block 1st Cross, Rammurthy nagar,
                Bangalore-560016
              </p>
            </div>
          </div>
          <div className="Choose_Payment_Option">
            <div className="heading-holder">
              <h4>Choose Payment Option</h4>
            </div>

            <div>
              <Form>
                <div className="radio-btn-input-holder">
                  <Form.Check // prettier-ignore
                    type="radio"
                    name="name"
                    label="Cash on delivery"
                  />

                  <Form.Check // prettier-ignore
                    type="radio"
                    name="name"
                    label="Online"
                  />
                </div>
              </Form>
            </div>
            <Form>
              <div className="payment_method_card bodr_bottom">
                <div className="heading-holder">
                  <h4>Pay with credit card</h4>
                </div>

                <div className="paycredit">
                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="online"
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Cash_On_Delivery_Process/visa.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>
                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="online"
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Cash_On_Delivery_Process/mastercard.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="payment_method_card">
                <div className="heading-holder">
                  <h4>Pay with UPI</h4>
                </div>

                <div className="paycredit">
                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="online"
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Cash_On_Delivery_Process/phone-pay.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>

                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="online"
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Cash_On_Delivery_Process/G_pay.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>

                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="online"
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Cash_On_Delivery_Process/Paytm.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>

          <div className="main-btn-div ">
            <Common_Button
              onClick={handlePrevious}
              text="Back"
              className=" back-btn"
            />

            <Common_Button
              onClick={handleNext}
              text="Save & Next"
              className=" save-btn"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default TwoPayment;
