import React, { useState } from "react";
import "./OurProduct.css";
import { Card, Col, CardFooter, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Product_Card from "../../Common-Component/Product_Card/Product_Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
library.add(fas);
const products = [
  {
    name: "Nescafe ",
    subText: "Classic Instant Coffee...",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/1p.png",
  },
  {
    name: "Kurkure ",
    subText: "Toofaani Mirch Chips",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/2p.png",
  },
  {
    name: "Beet Root ",
    subText: "1 KG",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/3p.png",
  },
  {
    name: "Himalaya ",
    subText: "Moisturizing Body Lotion",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/4p.png",
  },
  {
    name: "Huggies ",
    subText: "Wonder Pants",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/5p.png",
  },
  {
    name: "Dettol ",
    subText: "Antiseptic Liquid",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/6p.png",
  },
  {
    name: "Thums Up ",
    subText: "Thums Up 1 Lit.",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/7p.png",
  },
  {
    name: "Nescafe ",
    subText: "Classic Instant Coffee...",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/1p.png",
  },
];

const OurProduct = () => {
  const [showAll, setShowAll] = useState(false);
  const [numToShow, setNumToShow] = useState(4); // State to track number of cards to show

  const toggleShowAll = () => {
    setShowAll(!showAll);
    if (showAll) {
      // If currently showing all, reduce the number of rows displayed
      setNumToShow(4);
    } else {
      // If currently not showing all, show all rows
      setNumToShow(products.length);
    }
  };
  return (
    <section className="our-product">
      <Container>
        <div className=" mb-4 mt-5">
          <h2 className="title">All Products</h2>
        </div>

        {/* desktop_view_product_category_slider start */}
        <div className="desktop_view_product_category_slider">
          <Row>
            {/* {visibleProducts.map((product, index) => ( */}
            {products.slice(0, numToShow).map((product, index) => (
              <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>
                <Product_Card
                  image={product.image}
                  text={product.name}
                  subText={product.subText}
                  price={product.price}
                />
              </Col>
            ))}
          </Row>
        </div>

        {/* desktop_view_product_category_slider end */}

        {/* mobile_view_product_category_slider start */}

        <div className="mobile_view_product_category_slider">
          <Row className="ms-0 me-0">
            <Swiper
              className="our-product-slider mySwiper"
              modules={[Pagination]}
              pagination={{
                dynamicBullets: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                420: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                486: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
            >
              {products.slice(0, numToShow).map((product, index) => (
                <SwiperSlide>
                  <Product_Card
                    image={product.image}
                    text={product.name}
                    subText={product.subText}
                    price={product.price}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Row>
        </div>

        {/* mobile_view_product_category_slider end */}

        {showAll ? (
          <div className="view-all mt-4" onClick={toggleShowAll}>
            <p>View Less</p>
          </div>
        ) : (
          <div className="view-all mt-4" onClick={toggleShowAll}>
            <p>View All</p>
          </div>
        )}
      </Container>
    </section>
  );
};

export default OurProduct;
