import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./ShopBySeller.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "react-bootstrap";
import Popular_Product_Card from "../../Common-Component/Popular_Product_Card/Popular_Product_Card";

const ShopBySeller = () => {
  const sellercardsDetails = [
    {
      id: 1,
      content: "ECart Grocery Seller <br/>pvt. Ltd.",
      image:
        process.env.PUBLIC_URL + "/assets/Image/Homepage/shop-by/image-1.png",
      detailsLink: "/product",
    },
    {
      id: 2,
      content: "Baby Product Seller <br/>Pvt. Ltd.",
      image:
        process.env.PUBLIC_URL + "/assets/Image/Homepage/shop-by/image-2.png",
      detailsLink: "",
    },
    {
      content: "Home Appliances Seller <br/>Pvt. Ltd.",
      image:
        process.env.PUBLIC_URL + "/assets/Image/Homepage/shop-by/image-3.png",
    },
    {
      content: "Cappuccino Store <br/>Pvt. Ltd.",
      image:
        process.env.PUBLIC_URL + "/assets/Image/Homepage/shop-by/image-4.png",
    },
    {
      content: "ECart Grocery Seller <br/>Pvt. Ltd.",
      image:
        process.env.PUBLIC_URL + "/assets/Image/Homepage/shop-by/image-1.png",
    },
    {
      content: "ECart Grocery Seller <br/>Pvt. Ltd.",
      image:
        process.env.PUBLIC_URL + "/assets/Image/Homepage/shop-by/image-2.png",
    },
    {
      content: "ECart Grocery Seller <br/>Pvt. Ltd.",
      image:
        process.env.PUBLIC_URL + "/assets/Image/Homepage/shop-by/image-3.png",
    },
    {
      content: "ECart Grocery Seller <br/>Pvt. Ltd.",
      image:
        process.env.PUBLIC_URL + "/assets/Image/Homepage/shop-by/image-4.png",
    },
  ];

  return (
    <>
      {/* Shop-By-Seller Section Started */}

      <section className="Shop-By-Seller">
        <Container>
          <h1 className="title">Shop by Seller</h1>
          <div className="Seller-section">
            <Swiper
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                486: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1400: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1900: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {sellercardsDetails.map((item, index) => (
                <SwiperSlide key={index}>
                  <Link to={item.detailsLink}>
                    <Popular_Product_Card
                      className="main-section mb-5"
                      content={<span dangerouslySetInnerHTML={{ __html: item.content }} />}
                      image={item.image}
                      imgClassName="seller-img"
                      textClassName="local-text"
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Container>
      </section>

      {/* Shop-By-Seller Section End */}
    </>
  );
};

export default ShopBySeller;
