import React from "react";
import "./Faq.css";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
const Faq = () => {
  const accordfirst = [
    {
      eventskeys: 0,
      headings: "How do I register?",
      bodytext:
        ' You can register by clicking on the "Become a seller" link at the top of header. Please provide the information in the form that appears.  You can review the terms and conditions, provide your payment mode details and submit the registration information.',
    },
    {
      eventskeys: 1,
      headings: "Are there any charges for registration?",
      bodytext:
        ' You can register by clicking on the "Become a seller" link at the top of header. Please provide the information in the form that appears.  You can review the terms and conditions, provide your payment mode details and submit the registration information.',
    },
    {
      eventskeys: 2,
      headings: "Do I have to necessarily register to shop on Local Mandai?",
      bodytext:
        ' You can register by clicking on the "Become a seller" link at the top of header. Please provide the information in the form that appears.  You can review the terms and conditions, provide your payment mode details and submit the registration information.',
    },
    {
      eventskeys: 3,
      headings: "Can I have multiple registrations?",
      bodytext:
        ' You can register by clicking on the "Become a seller" link at the top of header. Please provide the information in the form that appears.  You can review the terms and conditions, provide your payment mode details and submit the registration information.',
    },
    {
      eventskeys: 4,
      headings: "Can I add more than one delivery address in an account?",
      bodytext:
        ' You can register by clicking on the "Become a seller" link at the top of header. Please provide the information in the form that appears.  You can review the terms and conditions, provide your payment mode details and submit the registration information.',
    },
  ];

  const accordsecond = [
    {
      eventskeys: 5,
      headings: "What is My Account?",
      bodytext:
        ' You can register by clicking on the "Become a seller" link at the top of header. Please provide the information in the form that appears.  You can review the terms and conditions, provide your payment mode details and submit the registration information.',
    },
    {
      eventskeys: 6,
      headings: "How do I reset my password?",
      bodytext:
        ' You can register by clicking on the "Become a seller" link at the top of header. Please provide the information in the form that appears.  You can review the terms and conditions, provide your payment mode details and submit the registration information.',
    },
    {
      eventskeys: 7,
      headings: "What is My Shopping List?",
      bodytext:
        ' You can register by clicking on the "Become a seller" link at the top of header. Please provide the information in the form that appears.  You can review the terms and conditions, provide your payment mode details and submit the registration information.',
    },
  ];

  const accordthird = [
    {
      eventskeys: 8,
      headings: "What are the modes of payment?",
      bodytext:
        ' You can register by clicking on the "Become a seller" link at the top of header. Please provide the information in the form that appears.  You can review the terms and conditions, provide your payment mode details and submit the registration information.',
    },
    {
      eventskeys: 9,
      headings:
        "Are there any other charges or taxes in addition to the price shown? Is VAT added to the invoice?",
      bodytext:
        ' You can register by clicking on the "Become a seller" link at the top of header. Please provide the information in the form that appears.  You can review the terms and conditions, provide your payment mode details and submit the registration information.',
    },
    {
      eventskeys: 10,
      headings: "Is it safe to use my credit/ debit card on local mandai?",
      bodytext:
        ' You can register by clicking on the "Become a seller" link at the top of header. Please provide the information in the form that appears.  You can review the terms and conditions, provide your payment mode details and submit the registration information.',
    },
    {
      eventskeys: 11,
      headings: "Where do I enter the coupon code?",
      bodytext:
        ' You can register by clicking on the "Become a seller" link at the top of header. Please provide the information in the form that appears.  You can review the terms and conditions, provide your payment mode details and submit the registration information.',
    },

  ];

  return (
    <>
      <section className="faq">
        <Container>
          <Row>
            <Col md={11} className="mx-auto">
              <div className="faq-banner">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/Image/faq/faq-banner.png"
                  }
                  className="faq-img"
                />
              </div>
            </Col>
            <Col md={10} className="mx-auto">
              <div className="faq-holder ">
                <h1>FAQs</h1>
                <h2 className="mb-4">Get Solutions Here</h2>
                <p>
                  Kindly check the FAQ below if you are not very familiar with
                  the functioning of this website. If your query is of urgent
                  nature and is different from the set of questions then please
                  contact us at: localmandaisupport@gmail.com
                </p>
              </div>

              <div className="registration-box mt-5">
                <h2>Registration</h2>
              </div>
              <Accordion defaultActiveKey={0}>
                {accordfirst.map((accords) => (
                  <Accordion.Item eventKey={accords.eventskeys}>
                    <Accordion.Header>{accords.headings}</Accordion.Header>
                    <Accordion.Body>
                      <p> {accords.bodytext}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}

              </Accordion>

              <div className="registration-box mt-5">
                <h2>Account Related</h2>
              </div>
              <Accordion defaultActiveKey={5}>
                {accordsecond.map((accords) => (
                  <Accordion.Item eventKey={accords.eventskeys}>
                    <Accordion.Header>{accords.headings}</Accordion.Header>
                    <Accordion.Body>
                      <p> {accords.bodytext}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}

              </Accordion>

              <div className="registration-box mt-5">
                <h2>Payment</h2>
              </div>
              <Accordion defaultActiveKey={8}>
                {accordthird.map((accords) => (
                  <Accordion.Item eventKey={accords.eventskeys}>
                    <Accordion.Header>{accords.headings}</Accordion.Header>
                    <Accordion.Body>
                      <p> {accords.bodytext}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}

              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Faq;
