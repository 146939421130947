import React from "react";
import "./Footer.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
const Footer = () => {
  return (
    <>
      <section className="footer">
        <Container>
          {/* subscribe-banner-start */}
          <div className='footer-banner'>
            <div className="footer-bannermain">
              <div className="footer-imgdiv">
                <img
                  className="footerimg"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Image/footer/individual.png"
                  }
                  alt="Logo"
                />
              </div>
              <div className="ban-contentmain">
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-9 col-sm-12 ">
                    <div className="banconts">
                      <h4 className="newssetl">
                        Subscribe to our newsletter to get updates on our latest
                        collections for you
                      </h4>
                      <p className="getss">Get Special deals with our subscription</p>
                      <div className="email-box">
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Enter you mail id for Newsletter"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <InputGroup.Text id="basic-addon2">
                            <Common_Button
                              text="  Subscribe"
                              className='subscribe-btn'
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* subscribe-banner-end */}
          <div className='row'>
            <div className='col-lg-3 col-md-4  '>
              <Link as={Link} to="/"><img className='headlogo' src={process.env.PUBLIC_URL + '/assets/Image/Logo/Local-MandaiFinal-Logo.png'} alt="Logo" /></Link>
              <div className='text-area-loaction'>
                <p>Akshya Nagar 1st Block 1st Cross,<br /> Rammurthy nagar, Pune - 411038</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-8  ">
              <Nav
                defaultActiveKey="/"
                className="justify-content-lg-center justify-content-md-center justify-content-start"
              >
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/">
                    HOME
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/near-me">
                    NEAR ME
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/about-us">
                    ABOUT US
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/contact-us">
                    CONTACT US
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/">
                    PRIVACY POLICY
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/">
                    TERMS & CONDITION
                  </NavLink>
                </Nav.Item>
              </Nav>
              <div className="location-holder">
                <div className="call-holder me-4">
                  <FontAwesomeIcon
                    icon="fa-solid fa-phone"
                    className="call-icon me-2"
                  />{" "}
                  <span className="me-2">+91 9876543210</span>
                </div>
                <div className="call-holder ">
                  <FontAwesomeIcon
                    icon="fa-solid fa-envelope"
                    className="call-icon me-2"
                  />
                  <span>localmandaisupport@gmail.com</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-5">
              <div className="address-holder">
                <div className="text-holder">
                  <p>Follow Us On:</p>
                </div>
                <div className="social-icon">
                  <img
                    className="sociallogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/Icon/facebook.png"
                    }
                    alt="sociallogo"
                  />
                  <img
                    className="sociallogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/Icon/linkdin.png"
                    }
                    alt="sociallogo"
                  />
                  <img
                    className="sociallogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/Icon/twitter.png"
                    }
                    alt="sociallogo"
                  />
                  <img
                    className="sociallogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/Icon/youtube.png"
                    }
                    alt="sociallogo"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="copyright-holder  text-center">
                <p>
                  Copyright ©2024 All Rights Reserved.{" "}
                  <Link to="https://profcymaglobal.com/" target="___blank">
                    Developed by Profcyma.
                  </Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Footer;
