import React from 'react'
import './OurStory.css'
const OurStory = () => {
    return (
        <>
            <section className='our-story'>
                <div className='container'>
                    <div className='row'>
                        <div className="text-center">
                            <h1 className="hot-title">Our Story</h1>
                            <div className="title-border"></div>
                            <p className="sub-text my-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer
                                took a galley of type and scrambled it to make a type specimen book. It has survived five centuries and
                                the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text
                                of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                                since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            <div className="col-md-10 mx-auto">
                                <h5 className="h5-quote">“ Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model search for evolved over sometimes by accident, sometimes on purpose ”</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurStory