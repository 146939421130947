import React from 'react'
import ProductBanner from './product-banner/ProductBanner'
import SeclectByCategory from './Select-by-category/SeclectByCategory'

const Product = () => {
    return (
        <>
            <ProductBanner />
            <SeclectByCategory />
        </>
    )
}

export default Product