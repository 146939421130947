import React from 'react'
import FirstVendor from './FirstVendor/FirstVendor'
import SecondVendor from './SecondVendor/SecondVendor'
import RateAndReview from './RateAndReview/RateAndReview'

const VendorPage = () => {
  return (
    <>
      <FirstVendor />
      <RateAndReview />
      <SecondVendor  heading="You may also like"/>
    </>
  )
}

export default VendorPage