import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Set_New_Password from "../Set_New_Password/Set_New_Password";
import LogIn from "../LogIn";
import Check_Your_Email from "../Check_Your_Email/Check_Your_Email";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";

function Forget_Password() {
  const [showSetNewPassword, setShowSetNewPassword] = useState(false);
  const [showCheckYourEmail, setShowCheckYourEmail] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const handleSubmit = () => {
    setShowCheckYourEmail(true);
    setTimeout(() => {
      setShowCheckYourEmail(false);
      setShowSetNewPassword(true);
    }, 3000); // Wait for 3 seconds before showing Set_New_Password
  };

  return (
    <div className="LogIn">
      {!showSetNewPassword && !showLogin && !showCheckYourEmail && (
        <div className="row bg-color me-0 ms-0">
          <div className="col-md-7 p-0">
            <div className="row justify-content-center me-0 ms-0  ">
              <div className="col-md-6">
                <div className="back_to_span">
                  <Link to={"/"}>
                    <p>Back to Home</p>
                  </Link>
                </div>

                <div className="heading-holder">
                  <h4>Reset Your Password</h4>
                  <p>
                    You will receive an email from us requesting you to reset
                    your password.
                  </p>
                </div>

                <div className="log_in_form">
                  <Form>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control
                        type="phone"
                        placeholder="Email or Mobile Number"
                      />
                    </Form.Group>
                  </Form>

                  <div className="btn-holder">
                    <Common_Button className="SubmitBtn" onClick={handleSubmit} text=" Submit" />
                  </div>

                  <div className="create_account">
                    {/* On click, set showLogin state to true */}
                    {/* <Link to={"/login"}> */}
                    <span
                      className="cancel-text"
                      onClick={() => setShowLogin(true)}
                    >
                      CANCEL
                    </span>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 p-0">
            <div className="img-section">
              <div className="img-holder">
                <img
                  className="log_in_img"
                  src={
                    process.env.PUBLIC_URL + "/assets/Image/log_in/log_in.png"
                  }
                  alt="log_in_img"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showCheckYourEmail && <Check_Your_Email />}
      {showSetNewPassword && <Set_New_Password />}
      {showLogin && <LogIn />}
    </div>
  );
}

export default Forget_Password;
