import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import Form from "react-bootstrap/Form";
import "./RateAndReview.css";
import Successfull_Modal from "../../Common_modal/Successfull_Modal/Successfull_Modal";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
const RateAndReview = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [ratings, setRatings] = useState(Array(5).fill(0));
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleStarClick = (starIndex) => {
    const newRatings = [...ratings];
    for (let i = 0; i < newRatings.length; i++) {
      newRatings[i] = i < starIndex ? 1 : 0;
    }
    setRatings(newRatings);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveImage = (index) => {
    const filteredFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(filteredFiles);
  };

  const renderStars = () => {
    return ratings.map((rating, index) => (
      <FontAwesomeIcon
        key={index}
        icon={rating === 1 ? solidStar : regularStar}
        className="star-icon me-1"
        onClick={() => handleStarClick(index + 1)}
      />
    ));
  };

  const reviews = [
    {
      id: 1,
      date: "05/3/2024",
      name: "John Doe",
      content:
        "A versatile and nutritious vegetable that adds crunch and flavor to any dish.",
      imageUrl: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/1-img.png",
      rating: 4,
    },
    {
      id: 2,
      date: "05/3/2024",
      name: "Paresh M",
      content:
        "A humble yet versatile vegetable, perfect for adding texture and nutrients to your meals",
      imageUrl: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/2-img.png",
      rating: 5,
    },
    {
      id: 3,
      date: "05/3/2024",
      name: "John Doe",
      content:
        "A crisp, affordable staple that adds a satisfying crunch to salads and stir-fries alike.",
      imageUrl: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/3-img.png",
      rating: 3,
    },
  ];

  return (
    <>
      <section className="rate-review">
        <div className="container">
          <div className="row">
            <div className="rate-holder text-center mb-3">
              <p>Rate & Review</p>
            </div>
            <div className="rating pt-3">
              <p>Rating (2/5)</p>
              <div className="">{renderStars()}</div>
              <p className="mt-4">Add Images / Videos</p>
              <div className="d-flex mt-3">
                <Form.Label className="plus-btn me-5">
                  <FontAwesomeIcon icon="plus" />
                  <Form.Control
                    type="file"
                    onChange={handleFileChange}
                    multiple
                  />
                </Form.Label>
                <div className="image-holder">
                  {selectedFiles.length > 0 ? (
                    selectedFiles.map((file, index) => (
                      <div className="file-img-holder" key={index}>
                        <img
                          className="img-logo"
                          src={URL.createObjectURL(file)}
                          alt={`Uploaded File ${index + 1}`}
                        />
                        <div
                          className="cross-overlay"
                          onClick={() => handleRemoveImage(index)}
                        >
                          <img
                            className="img-cross"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Image/Icon/cross-icon.png"
                            }
                            alt={`Cross Icon ${index + 1}`}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="file-img-holder">
                      <img
                        className="img-logo"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Vendor-Page/cut-veggie.png"
                        }
                        alt="Logo"
                      />
                      <div className="cross-overlay">
                        <img
                          className="img-cross"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/Icon/cross-icon.png"
                          }
                          alt="Logo1"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-holder mb-lg-0 mb-3 mt-3">
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>What did you like?</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Tell us more" />
                </Form.Group>
                <div className="button-holder text-md-end text-center">
                  <Common_Button text=" Cancel" className="btn-cancle me-2" />
                  <Common_Button onClick={() => { setModalShow(true); }} text=" post" className="btn-post me-2" />
                </div>
              </Form>
            </div>
            <div className="top-review">
              <h2>Top 3 Reviews</h2>
            </div>
            <Swiper
              className="mySwiper"
              modules={[Pagination]}
              pagination={{
                dynamicBullets: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                420: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                486: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
            >
              {reviews.map((review) => (
                <SwiperSlide key={review.id}>
                  <div className="review-card mb-5">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="date-holder">
                          <span>{review.date}</span>
                        </div>
                        <div className="name-text mt-3">
                          <h3>{review.name}</h3>
                          <p>{review.content}</p>
                          <div className="">
                            {renderStars()} {/* Render stars for each review */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="img-holder text-center">
                          <img
                            className="img-card"
                            src={review.imageUrl}
                            alt={`Reviewer ${review.id}`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {/* {reviews.map((review) => (
              <div key={review.id} className="col-lg-4 col-md-6 mb-3">
                <div className="review-card ">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="date-holder">
                        <span>{review.date}</span>
                      </div>
                      <div className="name-text mt-3">
                        <h3>{review.name}</h3>
                        <p>{review.content}</p>
                        <div className="">
                          {renderStars()} 
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="img-holder text-center">
                        <img
                          className="img-card"
                          src={review.imageUrl}
                          alt={`Reviewer ${review.id}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </section>
      <Successfull_Modal
        message=" Review added successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default RateAndReview;
