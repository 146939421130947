import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Breadcrumb, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import Cancle_Reason_Modal from "../../../Common_modal/Cancle_Reason_Modal/Cancle_Reason_Modal";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
const OrderReturn = (Props) => {
    const [modalShow, setModalShow] = useState(false);
    const [modalCanleReasonShow, setModalCanleReasonShow] = useState(false);
    const hideModalWithDelay = () => {
        setTimeout(() => {
            window.location.href = "/order-return-history";
            setModalShow(false);
        }, 3000);
    };
    return (
        <>
            <div className="Order_Cancle my-account my-account">
                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={"/"}>Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to={"/my-account"}>My account</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <Link>Order Return</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="product-section-div">
                        <div className="product-div">
                            <div className="row">
                                <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-4">
                                    <div className="product">
                                        <img
                                            className="img-set"
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Image/My_Profile/cabbege.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="text-holder">
                            <p>Order ID #675843</p>
                            <h4>Cabbage 1 Pc </h4>
                            <span>(Approx. 600 -1000 gm)</span>
                            <h4>₹100</h4>
                        </div>
                    </div>

                    <div
                        className="Cancellation"
                        onClick={() => setModalCanleReasonShow(true)}
                    >
                        <p>
                            Select a reason for returning{" "}
                            <FontAwesomeIcon
                                className="ms-2"
                                icon="fa-solid fa-angle-right"
                            />
                        </p>
                    </div>

                    <div className="Cancellation  Cancellation-reason">
                        <p>
                            Could you tell us a reason for returning (optional)
                            <span>(optional)</span>
                        </p>

                        <Form>
                            <Form.Control
                                as="textarea"
                                role="1"
                                placeholder="Type a reason"
                            />
                        </Form>

                        <div className="btn-holder">
                            <Link ><Common_Button text="Cancel" className='cancel-btn' />  </Link>
                            <Common_Button onClick={() => { setModalShow(true); hideModalWithDelay() }}
                                text="Submit" className='submit-btn' />
                        </div>
                    </div>
                </Container>
            </div>


            <Successfull_Modal
                message=" Order scheduled to return successfully! "
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

            <Cancle_Reason_Modal
                headername=" Select a reason for returingn"
                show={modalCanleReasonShow}
                onHide={() => setModalCanleReasonShow(false)}
            />
        </>
    )
}

export default OrderReturn