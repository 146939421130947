import React from "react";
import "./Category_Card.css";
import Common_Button from "../Common_Button/Common_Button";

function Category_Card({image, discount, heading, discountClassName, headingClassName }) {
  return (
    <>
      

      <div className="Category_Card ">
        <div className=" image-holder">
          <img
            className="banlogo"
            src={image}
            alt="banlogo"
          />
          <div className="text-overlay">
            <div>
              <p className={discountClassName}>{discount}</p>
              <h5 className={headingClassName}>{heading}</h5>
            </div>
            <div className="button-holder text-center">
              <Common_Button
                text=" SHOP NOW"
               className="btn-show"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Category_Card;
