import React, { useState } from "react";
import "./CheckoutProcess.css";
import OneAddress from "../CheckoutProcess/OneAdress/OneAdress";
import { Breadcrumb, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import TwoPayment from "./TwoPayment/TwoPayment";
import ThreeOrder from "./ThreeOrder/ThreeOrder";
const CheckoutProcess = () => {
  const [current, setCurrent] = useState(1);

  const stepLabels = [
    "1. Address Details",
    "2. Payment Options",
    "3. Confirm Order",
  ]; // Define custom labels for each step

  const stepLabelsBread = [
    "Address Details",
    " Payment Options",
    " Confirm Order",
  ]; // Define custom labels for each step

  const handleNext = () => {
    if (current < 3) {
      setCurrent(current + 1);
    }
  };

  const handlePrevious = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const isStepCompleted = (step) => {
    return step < current;
  };


  const getBreadcrumbItems = () => {
    const items = [
      <Breadcrumb.Item key="home">
        <Link to={"/"}>Home</Link>
      </Breadcrumb.Item>,
      <Breadcrumb.Item key="cart">
        <Link to={"/product-cart"}>Cart</Link>
      </Breadcrumb.Item>,
    ];

    for (let i = 1; i <= current; i++) {
      items.push(
        <Breadcrumb.Item key={i} active={i === current}>
          {stepLabelsBread[i - 1]}
        </Breadcrumb.Item>
      );
    }

    return items;
  };

  return (
    <>
      {/* checkout-process-step-form start  */}
      <section className="checkout-process-step-form Product_Cart">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>{getBreadcrumbItems()}</Breadcrumb>
          </div>

          <Form>
            <div className="progress-bar-container">
              <div className="step-row">
                {stepLabels.map((icon, index) => (
                  <div
                    key={index + 1}
                    className={`step-container ${index + 1 === current ? "active" : ""
                      }`}
                  >
                    <div>
                      <span className="step-text">{stepLabels[index]}</span>
                    </div>
                    <div className="circle-center">
                      <div
                        className={`circle ${isStepCompleted(index + 1) ? "completed" : ""
                          }`}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="steps">
              {current === 1 && <OneAddress handleNext={handleNext} />}
              {current === 2 && (
                <TwoPayment
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                />
              )}
              {current === 3 && <ThreeOrder handlePrevious={handlePrevious} />}
            </div>
          </Form>
        </div>
      </section>
      {/* checkout-process-step-form end  */}
    </>
  );
};

export default CheckoutProcess;
