import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./HomeBanner.css";

const HomeBanner = () => {
  const BannerData = [
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/home-banner2.jpg",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/home-banner2.jpg",
    },
  ];
  return (
    <>
      <section className="Home-Banner">
        <div className="container-fluid p-0">
          <Swiper
            spaceBetween={30}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            autoplay={{ delay: 300 }}
            className="mySwiper"
          >
            {BannerData.map((slide, index) => (
              <SwiperSlide key={index}>
                <img className="Banner-image" src={slide.imgSrc} alt="Banner" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default HomeBanner;
