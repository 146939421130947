import React from "react";
import "./OneAdress.css";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";

const OneAdress = ({ handleNext }) => {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  return (
    <>
      <section className="checkout-inner-steps">
        <div className="container">
          <div className="address-holders">
            <Form>
              <div className="row mb-4">
                <div className="col-lg-4  col-md-12 right_border">
                  <div>
                    <div className="main-heading-text">
                      <div className="mb-3">
                        <Form.Check // prettier-ignore
                          type="radio"
                          name="name"
                          label="Use existing address"
                        />
                      </div>
                    </div>

                    <Form>
                      <div className=" radio_input">
                        <div className="radio_input_field">
                          <Form.Label>Home</Form.Label>
                          <div className="flex-radio-input">
                            <Form.Check // prettier-ignore
                              type="radio"
                              name="name"
                            />

                            <Form.Control
                              type="text"
                              placeholder="2501 Better Street, Paola, KS, Kansas - 66071"
                            />
                          </div>
                        </div>

                        <div className="radio_input_field">
                          <Form.Label>Work</Form.Label>
                          <div className="flex-radio-input">
                            <Form.Check // prettier-ignore
                              type="radio"
                              name="name"
                            />

                            <Form.Control
                              type="text"
                              placeholder="2501 Better Street, Paola, KS, Kansas - 66071"
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="col-lg-8  col-md-12 left_border">
                  <div className="main-heading-text">
                    <div className="mb-3">
                      <Form.Check
                        type="radio"
                        name="name"
                        label="Add new address"
                      />
                    </div>
                  </div>

                  <Form>
                    <div className="address_input">
                      <div className="flex-radio-input">
                        <Form.Label>Name of address*</Form.Label>
                        <div className="address-name-select">
                          <Select
                            classNamePrefix={"selectOptions"}
                            options={options}
                            placeholder="--- please select ---"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="address_input">
                      <div className="flex-radio-input">
                        <Form.Label>Flat no./House/bldg*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=" Flat no./House/bldg"
                        />
                      </div>
                    </div>

                    <div className="address_input">
                      <div className="flex-radio-input">
                        <Form.Label>Colony/street/landmark</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=" Colony/street/landmark"
                        />
                      </div>
                    </div>

                    <div className="address_input">
                      <div className="flex-radio-input">
                        <Form.Label>Zip code*</Form.Label>
                        <Form.Control type="text" placeholder=" Zip code" />
                      </div>
                    </div>

                    <div className="address_input">
                      <div className="flex-radio-input">
                        <Form.Label>Mobile number*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=" Mobile number"
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </Form>
          </div>

          <div className="main-btn-div ">
            <Link to={"/product-cart"}>
              <Common_Button text="Back" className=" back-btn" />
            </Link>

            <Common_Button
              onClick={handleNext}
              text="Save & Next "
              className=" save-btn"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default OneAdress;
