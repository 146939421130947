import React from "react";
import "./Information.css";
import { Col, Container, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
function Information() {
  return (
    <>
      <section className="account-information my-account ">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item >
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/my-account"}>My account</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active><Link to={"/information"}>Account Information </Link></Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="back-text-holder">
            <Link to={"/my-account"}>
              <p>Back</p>
            </Link>
          </div>

          <div>
            <h2 className="title">Account Information</h2>
          </div>

          <div className="form-section">
            <div className="row justify-content-center">
              <div className="col-xxl-5 col-lg-6 mb-3">
                <div className="account-form">
                  <div className="row mb-3">
                    <div className="col-md-11 col-10">
                      <h4 className="form-title">Account Deatils</h4>
                    </div>
                    <div className="col-md-1 col-2">
                      <Link to={""}>
                        <h6 className="edit">Edit</h6>
                      </Link>
                    </div>
                  </div>
                  <Form>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Name*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control type="text" placeholder="Disha" />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Address*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control rows={2}  as="textarea" type="gender" placeholder="Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016" />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Mobile Number*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="phone-number"
                          placeholder="9756453456"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Email
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder="test1@gmail.com"
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Information;
